'use client'

import { useTheme } from 'next-themes'
import { useState, ReactNode } from 'react'

type ComparisonItem = {
  name: ReactNode
  value: number
  isPrimary: boolean
}

type TabData = {
  title: string
  subtitle: string
  unit: string
  metric: string
  comparison: string
  data: ComparisonItem[]
  isActive?: boolean
}

// Raw data without percentages
const tabsData: TabData[] = [

  {
    title: 'Time spent',
    subtitle: 'UI Testing',
    unit: 'minutes',
    metric: '20 tests each with 15m execution time',
    comparison: '(lower is better)',
    data: [
      { name: <>Limbar <br /><span className="text-gray-400">all plans</span></>, value: 15, isPrimary: true },
      { name: <>Others<span className="text-gray-400"><br />1 instance plans</span></>, value: 300, isPrimary: false },
      { name: <>Others<span className="text-gray-400"><br />2 instance plans</span></>, value: 150, isPrimary: false }
    ]
  },
  {
    title: 'Speed',
    subtitle: 'Github Actions',
    unit: 'seconds',
    metric: 'Time to provision',
    comparison: '(lower is better)',
    data: [
      { 
        name: 'Limbar', 
        value: 0.5, 
        isPrimary: true 
      },
      { 
        name: <>Google Emulator <span className="text-gray-400">with cache</span></>, 
        value: 20, 
        isPrimary: false 
      },
      { 
        name: <>Google Emulator <span className="text-gray-400">without cache</span></>, 
        value: 120, 
        isPrimary: false 
      }
    ],
    isActive: true
  },
  {
    title: 'Parallelism',
    subtitle: 'Maestro Testing',
    unit: '$',
    metric: 'Cost of each concurrent emulator',
    comparison: '(zero is better)',
    data: [
      { 
        name: 'Limbar', 
        value: 0, 
        isPrimary: true 
      },
      { 
        name: 'Google Emulator', 
        value: 0, 
        isPrimary: false 
      },
      { 
        name: <>Others <br/><span className="text-gray-400">exponentially increasing</span></>, 
        value: 50, 
        isPrimary: false 
      }
    ]
  },
  {
    title: 'CPU usage',
    subtitle: 'Live Interaction',
    unit: '%',
    metric: 'Minimum CPU usage',
    comparison: '(lower is better)',
    data: [
      { name: 'Limbar', value: 5, isPrimary: true },
      { name: 'Google Emulator', value: 400, isPrimary: false }
    ]
  }
]

// Process the data to add calculated percentages
const tabs = tabsData.map(tab => {
  const maxValue = Math.max(...tab.data.map(item => item.value))
  return {
    ...tab,
    data: tab.data.map(item => ({
      ...item,
      percentage: (item.value / maxValue) * 100 < 2 ? 2 : (item.value / maxValue) * 100
    }))
  }
})

const FeaturesComparison = () => {
  const { theme } = useTheme()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const currentTab = tabs[activeTabIndex]

  const formatValue = (value: number, unit: string) => {
    if (unit === '$') {
      return `${unit}${value}`
    }
    return `${value} ${unit}`
  }

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="text-center pb-12 md:pb-20">
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="font-semibold bg-linear-to-r from-blue-400 via-blue-500 to-purple-600 text-transparent bg-clip-text">Feel</span> the difference
            </h2>
            <p className="text-xl md:text-2xl text-gray-600 dark:text-gray-400 max-w-3xl mx-auto">
              It's 2025.<br />You can have as many Android emulators as you want.
            </p>
          </div>

          {/* Categories at the top */}
          <div className="max-w-3xl mx-auto mb-12">
            <div className="relative">
              {/* Tab buttons */}
              <div className="grid grid-cols-4 gap-8 relative z-10">
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    onClick={() => setActiveTabIndex(index)}
                    className="text-center focus:outline-hidden group pb-4 relative"
                  >
                    <div className={`font-medium mb-1 transition-colors ${
                      activeTabIndex === index ? 'text-blue-500' : 'text-gray-800 dark:text-gray-200 group-hover:text-blue-500'
                    }`}>
                      {tab.title}
                    </div>
                    <div className="text-gray-500 text-sm">{tab.subtitle}</div>
                    {/* Active tab indicator */}
                    {activeTabIndex === index && (
                      <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500" />
                    )}
                  </button>
                ))}
              </div>
              {/* Separator line */}
              <div className="absolute bottom-0 left-0 right-0 h-px bg-gray-200 dark:bg-gray-800" />
            </div>
          </div>

          {/* Comparison metrics */}
          <div className="max-w-3xl mx-auto">
            <h3 className="text-xl font-medium text-gray-800 dark:text-gray-200 mb-6">
              {currentTab?.metric}
              <p className="text-gray-500 font-normal text-sm">{currentTab?.comparison}</p>
            </h3>
            

            {currentTab?.data.map((item, index) => (
              <div key={index} className={item.isPrimary ? "mb-8" : "mb-6"}>
                <div className="flex items-center">
                  <div className="w-32 text-base font-medium text-gray-800 dark:text-gray-200">
                    {item.name}
                  </div>
                  <div className="grow relative">
                    <div 
                      className={`h-4 rounded-full transition-all duration-300 ${
                        item.isPrimary ? 'bg-blue-500' : 'bg-gray-300 dark:bg-gray-700'
                      }`}
                      style={{ width: `${item.percentage}%` }}
                    />
                    <span className="absolute left-[${item.percentage}%] top-full pt-1 ml-3 text-gray-800 dark:text-gray-200">
                      {formatValue(item.value, currentTab.unit)}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturesComparison 